import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import { MetaDescription, Title } from "../components/metadata";
import { H2, Link } from "../components/basic";
import Card from "../components/card";

const title = "Portfolio";

const Metadata = () => (
  <>
    <Title>{title}</Title>
    <MetaDescription>
      Some interesting projects that I have worked on.
    </MetaDescription>
  </>
);

const Portfolio = ({ data }) => {
  const AStarImageLink = (
    <Link to="https://github.com/jmgq/php-a-star">
      <GatsbyImage
        alt="A Star Algorithm - Weighted Graph"
        title="A* Algorithm"
        image={data.aStar.childImageSharp.gatsbyImageData}
      />
    </Link>
  );

  const SymfonyImage = (
    <GatsbyImage
      alt="Symfony"
      title="Symfony Framework"
      image={data.symfony.childImageSharp.gatsbyImageData}
    />
  );

  const GitHubImageLink = (
    <Link to="https://github.com/jmgq">
      <GatsbyImage
        alt="GitHub's Octocat"
        title="My personal GitHub account"
        image={data.github.childImageSharp.gatsbyImageData}
      />
    </Link>
  );

  const SkySportsImageLink = (
    <Link to="https://sport.sky.de/">
      <GatsbyImage
        alt="SkySports"
        title="SkySports"
        image={data.skySports.childImageSharp.gatsbyImageData}
      />
    </Link>
  );

  const NhsImage = (
    <GatsbyImage
      alt="NHS"
      title="NHS"
      image={data.nhs.childImageSharp.gatsbyImageData}
    />
  );

  return (
    <Layout title={title}>
      <Metadata />
      <p>
        I specialise in backend development, so I don’t have any flashy websites
        or designs to show, but here’s some of the work that I’m more proud of.
      </p>
      <H2>Open Source Software</H2>
      <Card media={AStarImageLink} title="A* Algorithm in PHP">
        {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
        This <Link to="https://github.com/jmgq/php-a-star">A* algorithm implementation</Link> has the following characteristics:
        <ul>
          <li>Works with any PHP version starting with PHP 5.3.</li>
          <li>Written using object orientation.</li>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
          <li>Fully tested: <Link to="https://coveralls.io/github/jmgq/php-a-star">100% code coverage</Link> using <Link to="https://phpunit.de/">PHPUnit</Link>.</li>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
          <li>A <Link to="https://travis-ci.org/jmgq/php-a-star">Continuous Integration server</Link> and a <Link to="https://scrutinizer-ci.com/g/jmgq/php-a-star/">Code Quality Tool</Link> have been set up.</li>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
          <li>Easily installable by using <Link to="https://getcomposer.org/">Composer</Link>.</li>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
          <li>Follows <Link to="https://semver.org/">semantic versioning</Link>.</li>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
          <li>Uses <Link to="https://www.docker.com/">Docker</Link> for local development.</li>
          <li>Ease of use: just a few steps are required to make it work.</li>
        </ul>
      </Card>
      <Card media={SymfonyImage} title="Symfony">
        {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
        <Link to="https://symfony.com/">Symfony</Link> is my favourite PHP framework, and since it is open source, I have made several contributions to its source code. I like to brag about that one time where my additions to the Stopwatch component were <Link to="https://symfony.com/blog/new-in-symfony-3-4-stopwatch-improvements">mentioned in the official Symfony blog</Link>.
      </Card>
      <Card media={GitHubImageLink} title="GitHub">
        {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
        What kind of developer would I be if I didn’t have a <Link to="https://github.com/jmgq">GitHub account</Link>?
      </Card>
      <H2>Proprietary Software</H2>
      <Card media={SkySportsImageLink} title="SkySports Germany">
        {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
        During my time in <Link to="https://www.skysports.com/">SkySports</Link>, I worked on several different parts of their infrastructure. I spent a significant amount of time working on the <Link to="https://sport.sky.de/">German version of SkySports</Link>.
      </Card>
      <Card media={NhsImage} title="NHS e-Referral Service">
        {/* eslint-disable-next-line react/jsx-one-expression-per-line, prettier/prettier */}
        I have worked in several NHS projects, and the most ambitious was the <Link to="https://digital.nhs.uk/services/nhs-e-referral-service">e-Referral Service</Link>: a group of secure applications that provides patients with a choice of hospitals and clinics when booking appointments, allows GPs to refer patients to a specialist whilst providing clinical assessment support, as well as other administration tasks, such as reporting, auditing, tracking, and so on.
      </Card>
    </Layout>
  );
};

Portfolio.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default Portfolio;

export const query = graphql`
  query {
    aStar: file(relativePath: { eq: "a-star-graph.png" }) {
      ...CardImage
    }
    symfony: file(relativePath: { eq: "symfony.jpg" }) {
      ...CardImage
    }
    github: file(relativePath: { eq: "github.jpg" }) {
      ...CardImage
    }
    skySports: file(relativePath: { eq: "skysports.png" }) {
      ...CardImage
    }
    nhs: file(relativePath: { eq: "nhs.jpg" }) {
      ...CardImage
    }
  }
`;
