import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";

const VerticallyAlignedContainer = styled.div.attrs({
  className: "pure-u-1 pure-u-md-1-2",
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h3`
  margin-bottom: 0;
`;

const Info = styled.div`
  margin-top: 1em;
`;

const Content = ({ className, title, children }) => (
  <VerticallyAlignedContainer className={className}>
    {title && <Title>{title}</Title>}
    <Info>{children}</Info>
  </VerticallyAlignedContainer>
);

const StyledContent = styled(Content)`
  box-sizing: border-box;

  /* pure-u-md-* >= 768px */
  @media (min-width: 768px) {
    padding-left: 1em;
  }
`;

const Media = VerticallyAlignedContainer;

const Card = ({ className, media, title, children }) => (
  <div className={`pure-g ${className}`}>
    <Media>{media}</Media>
    <StyledContent title={title}>{children}</StyledContent>
  </div>
);

const StyledCard = styled(Card)`
  background: rgb(250, 250, 250);
  margin: 2em auto;
  padding: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

Content.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Content.defaultProps = {
  title: null,
};

Card.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string,
  media: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

Card.defaultProps = {
  title: null,
};

export default StyledCard;

export const query = graphql`
  fragment CardImage on File {
    childImageSharp {
      gatsbyImageData(width: 654, layout: CONSTRAINED)
    }
  }
`;
